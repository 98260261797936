<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      v-loading="loading"
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="员工" prop="employeeId">
        <el-select
          v-model="dataForm.employeeId"
          filterable
          clearable
          placeholder="请选择员工"
          @visible-change="visibleSelectEmployee"
          @change="changeSelectEmployee"
        >
          <el-option
            v-for="item in employeeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="车辆" prop="carId">
        <el-select
          v-model="dataForm.carId"
          filterable
          clearable
          placeholder="请选择车辆"
          @visible-change="visibleSelectCar"
          @change="changeSelectCar"
        >
          <el-option
            v-for="item in carList"
            :key="item.id"
            :label="item.number"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="能源类型" prop="type">
        <el-select
          v-model="dataForm.type"
          filterable
          clearable
          placeholder="请选择能源类型"
        >
          <el-option
            v-for="item in carTypeList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <template v-if="dataForm.type !== ''">
        <el-form-item
          v-if="dataForm.type + '' === '0'"
          label="汽油类型"
          prop="type"
        >
          <el-select
            v-model="dataForm.gasolineType"
            placeholder="请选择汽油类型"
          >
            <el-option
              v-for="item in gasolineTypeOpt"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="`数量(${['升', '度', '立方米', '升'][dataForm.type]})`"
          prop="unit"
        >
          <el-input v-model="dataForm.unit" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label="金额(元)" prop="money">
          <el-input v-model="dataForm.money" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label="里程(公里)" prop="currentMileage">
          <el-input
            v-model="dataForm.currentMileage"
            placeholder="请输入"
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="`数量(${
            ['加油时间', '充电时间', '充气时间', '加油时间'][dataForm.type]
          })`"
          prop="supplementDate"
        >
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="dataForm.supplementDate"
            type="date"
            placeholder="选择月"
          >
          </el-date-picker>
        </el-form-item>
      </template>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { carNylx } from "@/assets/json/index";

export default {
  data() {
    return {
      loading: false,
      visible: false,

      dataForm: {
        id: "",
        carId: "",
        carNumber: "",
        type: "",
        unit: "",
        money: "",
        currentMileage: "",
        supplementDate: "",
        gasolineType: "",
        employeeId: "",
        employeeName: "",
        userId: "",
      },
      carList: [],
      nylx: [
        { name: "柴油", value: [3] },
        { name: "纯电动", value: [1] },
        { name: "气电混合", value: [1, 2] },
        { name: "汽油", value: [0] },
        { name: "油电混合", value: [0, 1, 3] },
        { name: "天然气", value: [2] },
        { name: "其它", value: [0, 1, 2, 3] },
      ],
      carTypeList: carNylx,
      employeeList: [],
      gasolineTypeOpt: [
        { name: "92", value: "92" },
        { name: "95", value: "95" },
        { name: "98", value: "98" },
      ],
      dataRule: {
        employeeId: [
          { required: true, message: "员工id不能为空", trigger: "blur" },
        ],
        carId: [{ required: true, message: "车辆id不能为空", trigger: "blur" }],

        type: [
          {
            required: true,
            message: "能源类型不能为空",
            trigger: "blur",
          },
        ],
        unit: [
          {
            required: true,
            message: "数量不能为空",
            trigger: "blur",
          },
        ],
        money: [
          {
            required: true,
            message: "金额不能为空",
            trigger: "blur",
          },
        ],
        currentMileage: [
          {
            required: true,
            message: "历程不能为空",
            trigger: "blur",
          },
        ],
        supplementDate: [
          {
            required: true,
            message: "时间不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    // dataRule() {},
  },
  methods: {
    init(id) {
      this.dataForm = {
        id: "",
        carId: "",
        carNumber: "",
        type: "",
        unit: "",
        money: "",
        currentMileage: "",
        supplementDate: "",
        gasolineType: "",
        employeeId: "",
        employeeName: "",
        userId: "",
      };
      this.employeeList = [];
      this.carList = [];
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(async () => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.loading = true;
          Promise.all([
            this.getCarList(),
            this.getEmployeeList(),
            this.getCarEnergyInfo(),
          ]).finally(() => {
            this.loading = false;
          });
        } else {
          this.dataForm.supplementDate = new Date();
        }
      });
    },
    // 获取能源信息
    async getCarEnergyInfo() {
      let res = await this.$http.carenergy.info({ id: this.dataForm.id });
      this.dataForm = res.data;
    },
    // 获取员工列表
    async getEmployeeList() {
      let res = await this.$http.employee.list({ page: 1, limit: 99999 });
      this.employeeList = res?.data?.list || [];
    },
    // 获取车辆列表
    async getCarList() {
      let res = await this.$http.car.list({ page: 1, limit: 99999 });
      this.carList = res?.data?.list || [];
    },

    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = JSON.parse(JSON.stringify(this.dataForm));

          if (this.dataForm.id) {
            // 修改
            this.$http.carenergy.update(requestDate).then((res) => {
              if (res && res.code === 0) {
                this.$message({
                  message: res.msg,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            // 新增
            delete requestDate.id;
            this.$http.carenergy.save(requestDate).then((res) => {
              if (res && res.code === 0) {
                this.$message({
                  message: res.msg,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },
    getType(e) {
      let nylx_data = this.nylx.find((item) => item.name === e);
      if (!nylx_data) return carNylx;
      let result = carNylx.filter((item) => {
        let find_index = nylx_data.value.findIndex(
          (nylx) => nylx === item.value
        );
        return find_index !== -1;
      });
      return result || carNylx;
    },
    // 能源类型初始化
    typeListInit() {
      // 当前选择的车辆的id
      let cur_carId = this.dataForm.carId;
      // 车辆列表
      let carList = this.carList;
      // 若没有选择车辆，或车辆列表为空则跳过
      if (!(cur_carId && carList?.length > 0)) return (this.typeList = carNylx);

      // 获取到当前选择的车辆数据
      let car_data = carList.find((item) => item.id === cur_carId);

      // 若未找到车辆则跳过
      if (!car_data?.nylx) return (this.typeList = carNylx);
      // 根据车辆类型设置不同的能源类型
      this.typeList = this.getType(car_data.nylx);
      console.log(this.typeList, "typeList");
    },
    // 员工
    visibleSelectEmployee(e) {
      if (e && this.employeeList.length === 0) {
        this.getEmployeeList();
      }
    },
    // 员工
    changeSelectEmployee() {
      let find_data = this.employeeList.find(
        (item) => item.id === this.dataForm.employeeId
      );
      this.dataForm.employeeName = find_data?.name;
      this.dataForm.userId = find_data?.uid;
    },

    // 车辆
    visibleSelectCar(e) {
      if (e && this.carList.length === 0) {
        this.getCarList();
      }
    },
    // 车辆
    changeSelectCar() {
      let find_data = this.carList.find(
        (item) => item.id === this.dataForm.carId
      );
      this.dataForm.carNumber = find_data?.number;

      // 车辆能源类型列表初始化
      this.typeListInit();
      // 若能源列表大于一取第一位
      this.dataForm.type = this.typeList[0].value;
    },
  },
};
</script>